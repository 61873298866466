<template>
     <CForm ref="form" class="needs-validation">
        <CRow>
            <CCol  tag="label" sm="3" class="col-form-label">
                Ürün
            </CCol>
            <CCol>
                <CSelect
                    placeholder="Seçiniz.."
                    horizontal
                    :options="products"
                    :value.sync="data.product"
                    required
                /> 
            </CCol>
            
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "KLAddPhoto",
        computed:{
            products: function() {
                let data = []
                this.$store.getters.activeProducts.map(r => data.push({value:r.name, label: r.name}))
                return data
            },
        },
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
            validated: Boolean,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create')
                    this.data = {...this.params}
            },

        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
            }
        },
    }
</script>